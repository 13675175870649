import { toJSON } from "./formData";

type AppFormDataSource = FormData | URLSearchParams | AppFormData | HTMLFormElement;

export default class AppFormData {
  #data: FormData = new FormData();

  constructor(data?: AppFormDataSource) {
    if (data instanceof AppFormData) {
      this.#data = data.toFormData();
    } else if (data instanceof FormData) {
      this.#data = data;
    } else if (data instanceof URLSearchParams) {
      for (const [key, value] of data.entries()) {
        this.#data.set(key, value);
      }
    } else {
      this.#data = new FormData(data);
    }
  }

  set(name: string, value: string /* TODO: allow any type */) {
    this.#data.set(name, value);

    return this;
  }

  append(name: string, value: string /* TODO: allow any type */) {
    this.#data.append(name, value);

    return this;
  }

  get<T = string | null>(name: string): T {
    return this.#data.get(name) as T;
  }

  getAll(name: string) {
    return this.#data.getAll(name);
  }

  merge(data?: AppFormDataSource) {
    const formData = new AppFormData(data);

    for (const [key, value] of formData.entries()) {
      this.#data.set(key, value);
    }

    return this;
  }

  mergeAll(data?: AppFormDataSource) {
    const formData = new AppFormData(data);

    for (const [key, value] of formData.entries()) {
      this.#data.append(key, value);
    }

    return this;
  }

  entries() {
    return this.#data.entries();
  }

  toFormData() {
    return this.#data;
  }

  toJSON<T>() {
    return toJSON<T>(this.#data);
  }

  /**
   * Used to get an object that isn't parsed deeply.
   *
   * @example
   * ```ts
   * const formData = new AppFormData();
   * formData.set("person.name", "John Doe");
   * formData.set("person.age", "30");
   *
   * const flatJSON = formData.toFlatJSON();
   * // flatJSON = { "person.name": "John Doe", "person.age": "30" }
   * ```
   */
  toFlatJSON() {
    return Object.fromEntries(this.#data.entries()) as Record<string, string>;
  }

  toURLSearchParams() {
    return new URLSearchParams(this.toFlatJSON());
  }
}
